const SimpleHeader = () => {
    return (<header className="flex items-center mx-auto">
        <div className="flex justify-between bg-[#833014] w-full py-4 px-6">
            <div className="pl-4 hidden sm:block">
                <img src="/img/logo.png" className="h-[55px]" alt="Logo"></img>
            </div>

            <div className="flex flex-wrap items-center justify-center gap-4">
                <a href={"https://www.facebook.com/profile.php?id=61568138258889"}
                   className="bg-[#772a10] hover:bg-[#5d200c] text-[#ed9071] rounded-[20px] px-4 py-3 sm:w-auto w-full"
                   target={"_blank"}>
                    Página Facebook
                </a>
                <a href={"https://chat.whatsapp.com/Ke3Stre1W7NCGpAqnixBc7"}
                   className="bg-[#772a10] hover:bg-[#5d200c] text-[#ed9071] rounded-[20px] px-4 py-3 sm:w-auto w-full"
                   target={"_blank"}>
                    Grupo WhatsApp
                </a>
                <a href={"https://downloads.ddtankarena.com/"}
                   className="bg-[#772a10] hover:bg-[#5d200c] text-[#ed9071] rounded-[20px] px-4 py-3 sm:w-auto w-full"
                   target={"_blank"}>
                    Downloads
                </a>
            </div>
        </div>
    </header>);
}

export default SimpleHeader;