import '../styles/presentation.css';

const NovoServidorPresentation = () => {

    return (

        <>
            <br></br>
            <div className="content">
                <h1>🎯✨ Prepare-se para uma nova era no DDTank! ✨🎯</h1>
                <p>É com muita emoção que anunciamos o lançamento do <strong>DDTank Arena</strong>! Nosso novo servidor
                    criado com carinho especial por jogadores para jogadores. Nos esforçamos muito para corrigirmos o
                    máximo possível dos bugs conhecidos dos atuais servidores de DDTank. Nosso objetivo é oferecer uma
                    experiência divertida e equilibrada para todos, de maneira honesta e justa, prolongando o máximo
                    possível a duração dos canais para que cada esforço de cada jogador e de nossa equipe valha a pena.
                </p>

                <h2>Por que jogar no DDTank Arena?</h2>
                <ul>
                    <li>Reunimos uma grande equipe de desenvolvedores, administradores e moderadores, com anos de
                        experiência, cansados dos servidores tradicionais de DDTank que pensam somente no lucro e fazem
                        pouco caso dos jogadores que gostam verdadeiramente do jogo, assim como nós.
                    </li>
                    <li>Nosso primeiro servidor será na versão <strong>4.1 Clássica</strong>, com um toque de
                        modernidade. Implementamos sistemas e melhorias para que o jogo não se torne monótono e
                        enjoativo, oferecendo uma experiência equilibrada em todos os sistemas, desde o fortalecimento
                        até os drops. Experimente a nostalgia com a versão 4.1, que marcou nossa geração!
                    </li>
                    <li><strong>Correções e Melhorias:</strong> Corrigimos problemas antigos e fizemos otimizações para
                        um jogo ainda mais equilibrado, garantindo partidas mais justas e uma jogabilidade competitiva.
                    </li>
                    <li><strong>Eventos Exclusivos de Lançamento:</strong> Para a semana de lançamento, preparamos
                        eventos exclusivos para quem fizer o pré-cadastro. Prepare-se para uma temporada cheia de
                        recompensas raras e itens únicos!
                    </li>
                    <li><strong>Servidor Estável e Rápido:</strong> Nosso servidor oferece estabilidade e desempenho,
                        garantindo que você possa se concentrar em se divertir e derrotar seus adversários!
                    </li>
                    <li><strong>Reencontre velhos amigos e conquiste novos rivais:</strong> Forme sua guilda, planeje
                        suas estratégias e domine o <strong>Templo Perdido</strong>!
                    </li>
                    <li><strong>Disponível para PC e Android</strong> e possivelmente iOS: Jogue onde e de onde quiser,
                        com progresso sincronizado entre as plataformas.
                    </li>
                    <li><strong>Divulgação do servidor:</strong> Todo o faturamento será reinvestido em captação de
                        novos jogadores e melhorias.
                    </li>
                    <li><strong>Queremos mudar a percepção dos jogadores sobre os servidores de DDTank</strong>. Faremos
                        juntos o melhor servidor de todos os tempos!
                    </li>
                </ul>

                <p>📲 Siga nossas<a href="https://www.instagram.com/ddtankarena/" target="_blank"> redes
                    sociais </a> para mais informações e fique de olho nos eventos que vão rolar. E, claro, compartilhe
                    essa novidade com seus amigos de batalha!</p>
                <p>👥 <a href="https://chat.whatsapp.com/Ke3Stre1W7NCGpAqnixBc7" target="_blank">Link do Grupo
                    Oficial</a></p>
            </div>
        </>

    );

};

export default NovoServidorPresentation;